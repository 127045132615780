<template>
    <div v-for="trackerGroup in trackerGroups">
        <i class="material-icons-outlined alert-icon" @click="editAlerts(trackerGroup)">notification_important</i>
        <SensorGauges :deviceOffline="false" 
        :loaded="true" :data="trackerGroup.DonutData" :doughnutOptions="trackerGroup.DonutOptions">
    </SensorGauges>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref, defineExpose } from 'vue'
import { ITrackerData } from '../models/tracker-data.js';
import { TrackerGroup } from '../models/tracker-group.js';
import SensorGauges from "./SensorGauges.vue";

const trackerGroups = ref<TrackerGroup[]>([]); // trackerGroups

const props = defineProps({
    feature: { type: Number, required: true },
    trackerTraces: { type: Array<ITrackerData>, required: true },
    editAlerts: { type: Function, required: true },
    device: { type: Object }
})

async function initializeTrackerGroups(device: any, trackerTraces: Array<ITrackerData>) {
    props.device = device;
    props.trackerTraces = trackerTraces;
    props.feature = device.feature;
    trackerGroups.value = [new TrackerGroup(trackerTraces, device.feature, device)];
};

defineExpose({
    initializeTrackerGroups
});
</script>

<style scoped>
.subtxt {
    display: inline-block;
    color: #ffffff;
    padding: 5px;
    margin-bottom: 0;
    font-size: 0.8rem;
}
</style>